import {Inject, Injectable} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {Entity} from '../models/entity';
import {VmdConstants} from '../constants/vmd-constants';
import {IOdcConfig, ODC_CONFIG} from '../../odc-config';
import {SessionService} from './session.service';
import {I18N_NAV_MESSAGES_FR} from '../i18n/nav-messages_fr';
import {I18N_NAV_MESSAGES_EN} from '../i18n/nav-messages_en';
import {Router} from '@angular/router';
import {ModalSelectors} from '../constants/modal-selectors';

declare let $: any;

@Injectable()
export class ModalService {

    modalIgnoredRoutePath: {} = {
        '#modal-password': [I18N_NAV_MESSAGES_FR.ODC_NAV_LOGIN_PATH, I18N_NAV_MESSAGES_EN.ODC_NAV_LOGIN_PATH]
    };

    private context: string = null;

    private emitCloseModalAlertSource = new Subject<string>();
    closeModalAlertEmitted = this.emitCloseModalAlertSource.asObservable();

    private emitValidClickOnChoice1Source = new Subject<string>();
    validClickOnChoice1Emitted = this.emitValidClickOnChoice1Source.asObservable();

    private emitValidClickOnChoice2Source = new Subject<string>();
    validClickOnChoice2Emitted = this.emitValidClickOnChoice2Source.asObservable();

    private emitErrorSource = new Subject<string>();
    errorgEmitted = this.emitErrorSource.asObservable();

    private emitEsignLoadingSource = new Subject<boolean>();
    esignLoadingEmitted = this.emitEsignLoadingSource.asObservable();

    private emitEsignClosePopupSource = new Subject<string>();
    esignClosePopupEmitted = this.emitEsignClosePopupSource.asObservable();

    private emitEsignCloseConfirmPopupSource = new Subject<boolean>();
    esignCloseConfirmPopupEmitted = this.emitEsignCloseConfirmPopupSource.asObservable();

    private emitOpenVideoPopupSource = new Subject<{ id: string, title: string }>();
    openVideoPopupEmitted = this.emitOpenVideoPopupSource.asObservable();

    private emitPdfLinkSource = new Subject<boolean>();
    pdfLinkEmitted = this.emitPdfLinkSource.asObservable();

    private emitPdfLoadingSource = new Subject<boolean>();
    pdfLoadingEmitted = this.emitPdfLoadingSource.asObservable();

    private emitValidClickOnPlatformBackSource = new Subject<string>();
    validClickOnPlatformBack = this.emitValidClickOnPlatformBackSource.asObservable();

    private emitValidClickOnPlatformContinueSource = new Subject<string>();
    validClickOnPlatformContinue = this.emitValidClickOnPlatformContinueSource.asObservable();

    private emitShowAuthenticatePasswordFormSource = new Subject();
    showAuthenticatePasswordForm = this.emitShowAuthenticatePasswordFormSource.asObservable();

    private emitShowDefineNewPasswordSource = new Subject();
    showDefinePassword = this.emitShowDefineNewPasswordSource.asObservable();

    private emitShowForgotPasswordSource = new Subject();
    showForgotPassword = this.emitShowForgotPasswordSource.asObservable();

    private emitShowCreateProfileSource = new Subject();
    showCreateProfile = this.emitShowCreateProfileSource.asObservable();

    private emitOpenAddEntityPopupSource = new Subject<Entity>();
    openAddEntityPopupEmitted = this.emitOpenAddEntityPopupSource.asObservable();

    private emitShowSaveAuthFormSource = new Subject();
    showSaveAuthForm = this.emitShowSaveAuthFormSource.asObservable();

    constructor(private translate: TranslateService,
                private sessionService: SessionService,
                private route: Router,
                private translatePipe: TranslatePipe,
                @Inject(ODC_CONFIG) public config: IOdcConfig) {
    }

    emitCloseModalAlert(modalSelector: any) {
        this.emitCloseModalAlertSource.next(modalSelector);
    }

    emitValidClickOnChoice1(context: string = null) {
        if (null !== context) {
            this.context = context;
        }
        this.emitValidClickOnChoice1Source.next(this.context);
    }

    emitValidClickOnChoice2(context: string = null) {
        if (null !== context) {
            this.context = context;
        }
        this.emitValidClickOnChoice2Source.next(this.context);
    }

    emitError(value: string) {
        this.emitErrorSource.next(value);
    }

    emitEsignLoading(value: boolean) {
        this.emitEsignLoadingSource.next(value);
    }

    emitEsignClosePopup() {
        this.emitEsignClosePopupSource.next();
    }

    emitEsignCloseConfirmPopup(value: boolean) {
        this.emitEsignCloseConfirmPopupSource.next(value);
    }

    emitPdfLink(isAdditional: boolean = false) {
        this.emitPdfLinkSource.next(isAdditional);
    }

    emitPdfLoading(value: boolean) {
        this.emitPdfLoadingSource.next(value);
    }

    emitOpenAddEntity(entity: Entity) {
        this.emitOpenAddEntityPopupSource.next(entity);
    }

    emitValidClickOnPlatformBack() {
        this.emitValidClickOnPlatformBackSource.next();
    }

    emitValidClickOnPlatformContinue() {
        this.emitValidClickOnPlatformContinueSource.next();
    }

    emitShowAuthenticatePasswordForm() {
        this.emitShowAuthenticatePasswordFormSource.next();
    }

    emitShowForgotPassword() {
        this.emitShowForgotPasswordSource.next();
    }

    emitShowCreateProfile() {
        this.emitShowCreateProfileSource.next();
    }

    emitShowDefineNewPassword() {
        this.emitShowDefineNewPasswordSource.next();
    }

    emitShowSaveAuthForm() {
        this.emitShowSaveAuthFormSource.next();
    }

    openModalChangingSignificantField(fieldTitle: string): void {
        this.openModal('ODC_MODAL_CHANGING_SIGNIFICANT_FIELD_TITLE',
            'ODC_MODAL_CHANGING_SIGNIFICANT_FIELD',
            '#modal-continue-back',
            fieldTitle,
            undefined,
            undefined,
            {
                field: this.translatePipe.transform(fieldTitle)
                    .replace(/&nbsp;/g, ' ')
                    .replace(/\s*\?([^?]*)$/, '$1')
            })
    }

    openModal(
        titleI18nKey: string,
        bodyI18nKey: string,
        modalSelector = ModalSelectors.ALERT_MODAL,
        context: string = null,
        optionalText?: string,
        buttonsText?: string[],
        bodyI18nKeyParams?: { [key: string]: any }
    ): void {

        if (this.isIgnoredRoutePath(modalSelector)) {
            return;
        }

        this.context = context;

        if (!$(modalSelector).length) {
            return;
        }

        setTimeout(() => {

            $(modalSelector).on('hidden.bs.modal', () => {
                this.emitCloseModalAlert(modalSelector);
            });

            if (titleI18nKey && bodyI18nKey) {
                this.translate.get(titleI18nKey).subscribe(title => {
                    $(modalSelector + ' .modal-title').html(title);
                    if (bodyI18nKeyParams) {
                        const body = this.translatePipe.transform(bodyI18nKey, bodyI18nKeyParams);
                        $(modalSelector + ' .modal-body').html(body);
                    } else {
                        this.translate.get(bodyI18nKey).subscribe(body => {
                            $(modalSelector + ' .modal-body').html(body);
                        });
                    }
                });
            }

            if (buttonsText && buttonsText.length > 0) {
                const primaryButtonI18nKey = buttonsText[0];
                const defaultButtonI18nKey = buttonsText[1];

                if (primaryButtonI18nKey) {
                    this.translate.get(primaryButtonI18nKey).subscribe(buttonText => {
                        $(modalSelector + ' .btn-primary').attr('title', buttonText);
                        $(modalSelector + ' .btn-primary').html(buttonText);
                    });
                }

                if (defaultButtonI18nKey) {
                    this.translate.get(defaultButtonI18nKey).subscribe(buttonText => {
                        $(modalSelector + ' .btn-default').attr('title', buttonText);
                        $(modalSelector + ' .btn-default').html(buttonText);
                    });
                }
            }

            if (optionalText) {
                $(modalSelector + ' .modal-specific-text').html(optionalText);
            }

            $(modalSelector).modal({
                backdrop: 'static'
            });

            $(modalSelector).modal('show');
        });
    }

    showCuratorshipResidentQuebecModal() {
        this.openModal('CURATORSHIP_RESIDENT_OF_QUEBEC_TITLE',
            'CURATORSHIP_RESIDENT_OF_QUEBEC_BODY',
            '#modal-alert-generic', 'MODAL_MODIFY_CURATORSHIP_CONTEXT',
            null,
            ['BTN_CHANGE', 'BTN_MODIFY']);
    }

    triggerEnglishQuebecResidentModal() {
        const modalAlreadyTriggered = sessionStorage.getItem(VmdConstants.ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY) === true.toString();

        if (this.config.TOGGLE_PDF_LANG && !this.config.IS_PLEIN_EX &&
            this.sessionService.getOrigin() === 'WEB' && !modalAlreadyTriggered) {
            const isEnglishSpeaker = this.translate.currentLang === VmdConstants.LANG_EN;

            if (isEnglishSpeaker) {

                this.openModal(
                    'ENGLISH_QUEBEC_RESIDENTS_MODAL_TITLE',
                    'ENGLISH_QUEBEC_RESIDENTS_MODAL_MESSAGE',
                    '#modal-alert',
                    null,
                    null,
                    ['BTN_OK']
                );

                sessionStorage.setItem(VmdConstants.ENGLISH_QUEBEC_RESIDENT_MODAL_TRIGGERED_SESSION_KEY, true.toString());
            }
        }
    }

    private isIgnoredRoutePath(modalSelector: string): boolean {
        const modalIgnoredRoutePaths = this.modalIgnoredRoutePath[modalSelector];

        if (modalIgnoredRoutePaths) {
            return modalIgnoredRoutePaths.includes(this.route.url.substring(1));
        }

        return false;
    }
}
