import {Functions} from '../utils/functions';
import {VmdConstants} from '../constants/vmd-constants';
import {UserIdentity} from './user-identity';

export class Requester {

    formLang: string;

    // Informations personelles du client
    gender: string;
    userAddressTypeSelected: string;
    userAddressCity: string;
    userAddressCitySec: string;
    userAddressPostalCode: string;
    userAddressPostalCodeSec: string;
    userAddressProv: string;
    userAddressProvSec: string;
    userAddressCountry: string = VmdConstants.COUNTRIES.CANADA;
    userAddressCountrySec: string = VmdConstants.COUNTRIES.CANADA;
    userAddressStreet: string;
    userAddressStreetSec: string;
    userAddressUnit: string;
    userAddressUnitSec: string;
    userAddressUnstructured: string[];
    userDOB: string;
    userEmail: string;
    userFirstName: string;
    userHomePhone: string;
    userJobStatus: string;
    userLastName: string;
    userMaritalStatus: string;
    userOtherPhone: string;
    userMobilePhone: string;
    userSIN: string;
    userIsCurrentAddress: boolean;
    // Information sur le conjoint
    spouseGender: string;
    spouseFirstName: string;
    spouseLastName: string;
    spouseDOB: Date;
    spouseSIN: string;
    spouseJobStatus: string;
    spouseEmployerName: string;
    spouseEmployerActivity: string;
    spouseJobTitle: string;

    // Information sur le conjoint cotisant
    contributingSpouseGender: string;
    contributingSpouseFirstName: string;
    contributingSpouseLastName: string;
    contributingSpouseDOB: Date;
    contributingSpouseSIN: string;

    // Occupation et Employeur
    userEmployerIsBroker: string; // BKR_EMP_CDE
    userIsInvestmentAdvisorIndicator: boolean; // IA_IND - 0/1
    userEmployerName: string; // Requester.EMP_NAM - Nom de l.employeur
    userEmployerActivity: string; // Requester.ENT_ATY_SCT - Secteur d'activité
    userJobTitle: string; // Requester.JOB_TTL - Job title
    userSameAddressWithBrokerEmployeeIndicator: boolean; // HME_BKR_RES - Y
    userSameAddressWithBrokerEmployeeVmdIndicator: boolean; // HME_BKR_RES_VMD_IND - Y
    userSameAddressWithBrokerEmployeeFirmName: string; // HME_BKR_RES_INS_NAM
    userSameAddressWithBrokerEmployeeFirstName: string; // HME_BKR_RES_FST_NAM
    userSameAddressWithBrokerEmployeeLastName: string; // HME_BKR_RES_LST_NAM
    userSameAddressWithBrokerEmployeeLink: string; // HME_BKR_RES_LNK
    userSameAddressWithBrokerEmployeeLinkList: string; // HME_BKR_RES_LNK_LST
    userChargeOfParentEmployeeAtVMDFirstName: string;
    userChargeOfParentEmployeeAtVMDLastName: string;
    percentageOwner: number;

    // Lieu de résidence aux fins fiscales
    userFiscalRelationCanada: boolean; // FISCAN si coché
    userFiscalRelationUsa: boolean; // FISUSA si coché
    userFiscalRelationOther: boolean; // FISOTH si coché
    userNifMissingReason: boolean | null; //  True or false for NIF missing reason
    userNifMissingReason2: string; // 2nd Dropdown for nif missing
    userNifMissingReason3: string; // 3rd Dropdown for nif missing
    userCitizenship: boolean; // CAN si coché
    userCitizenshipUS: boolean; // USA si coché
    addUserOtherCitizenship: boolean; // 1 si ajout other citizenship
    userOtherCitizenship: string;
    usaNif: string; // Nif usa
    userCountryNif2: string; // Nif other 1
    userCountryNif3: string; // Nif other 2
    codePremierAutreNif: string;
    codeSecondAutreNif: string;
    fiscalUsCitizenshipIndicator: boolean;
    fiscalAddressDifferentIndicator: boolean;
    fiscalAddressCountry: string; // Code pays
    fiscalAddressProvince: string; // Code province

    // Comptes de courtage
    brokerageOtherAccountsIndicator: boolean;
    brokerageOtherAccountsFirmName: string;
    brokerageOtherAccountsAccountType: string;
    userInitieIndicator: boolean;
    userInitieIndicatorOSS: boolean;
    userInitieCompanyName: string;
    userInitieCompanySymbol: string;
    userInitieMarket: string;
    userMainShareholderIndicator: boolean;
    userMainShareholderIndicatorOSS: boolean;
    userMainShareholderCompanyName: string;
    userMainShareholderCompanySymbol: string;
    userMainShareholderMarket: string;
    userPoliticallyExposedForeignPersonIndicator: boolean;
    userFinancialInterestIndicator: boolean;
    userTrustedPersonIndicator: boolean;
    userOtherTradingPersonIndicator: boolean;
    brokeragePurpose: string;
    brokeragePurposeOther: string;
    userBrokerageKnowledge: string;
    userOtherGuarantingPersonIndicator: boolean;
    userBrokerageCreditBalanceList: string;
    userBrokerageDirectDeposit: boolean;
    userBrokerageWithdrawal: boolean;
    authorizeClientInfoPresence: boolean;

    // Institution financiere
    userAnnualIncome: number;
    userNetCapitalAsset: number;
    userNetLiquidityAsset: number;
    userTotalNetWorth: number;
    userBankId: number;
    userBankBranchNumber: number;
    userBankAccountNumber: number;
    userBankPhone: number;

    // Communication avec les propriétaires véritables
    legalShareHolderMsgWarning: boolean;

    // Communication avec les actionnaires
    userDisclaimerIndicator: boolean;
    userReceiptDocumentSecuritiesHolderIndicator: string;
    userElectronicDocumentSecuritiesHolderIndicator: boolean;
    userMetAtOffice: boolean;
    // Used in the BRANCH context / Utilisé lors du contexte CAISSE
    userIdentityAdvisorFamilyName: string;
    userIdentityAdvisorFirstName: string;
    userIdentityAdvisorBranchName: string;
    userIdentityAdvisorInstitution: number;
    userIdentityAdvisorTransit: number;

    userIdentityCode: string;
    userIdentityIDNumber: string;
    userIdentityExpirationDate: string;
    userIdentityProvince: string;
    userIdentityCountry: string;
    userIdentityVerificationDate: string;

    userIdentities: UserIdentity[];
    userIdentityAdditional: boolean;

    // Si 2eme ou 3eme titulaire à la meme adresse que le 1er
    isSameAddressAsFirstIndicator: boolean;

    consent: boolean;

    // specific consent
    personalInformationSpecificConsentIndicator: boolean;


    init(value: any) {
        if (typeof value === 'undefined') {
            return null;
        }

        return value;
    }

    constructor(obj?: any) {
        if (obj) {

            this.formLang = Functions.getLanguage(obj.formLang);

            // Informations personelles du client
            this.gender = this.init(obj.gender);
            this.userAddressCity = Functions.initByLength(obj.userAddressCity, 32);
            this.userAddressCitySec = Functions.initByLength(obj.userAddressCitySec, 32);
            this.userAddressPostalCode = this.init(obj.userAddressPostalCode);
            this.userAddressPostalCodeSec = this.init(obj.userAddressPostalCodeSec);
            this.userAddressProv = this.init(obj.userAddressProv);
            this.userAddressProvSec = this.init(obj.userAddressProvSec);
            this.userAddressCountry = Functions.initCountryAddress(obj.userAddressCountry);
            this.userAddressCountrySec = Functions.initCountryAddress(obj.userAddressCountrySec);
            this.userAddressStreet = this.init(obj.userAddressStreet);
            this.userAddressStreetSec = this.init(obj.userAddressStreetSec);
            this.userAddressUnit = Functions.initByLength(obj.userAddressUnit, 8);
            this.userAddressUnitSec = Functions.initByLength(obj.userAddressUnitSec, 8);
            this.userAddressUnstructured = this.init(obj.userAddressUnstructured);
            this.userDOB = this.init(obj.userDOB);
            this.userEmail = this.init(obj.userEmail);
            this.userFirstName = this.init(obj.userFirstName);
            this.userHomePhone = this.init(obj.userHomePhone);
            this.userJobStatus = this.init(obj.userJobStatus);
            this.userLastName = this.init(obj.userLastName);
            this.userMaritalStatus = this.init(obj.userMaritalStatus);
            this.userOtherPhone = this.init(obj.userOtherPhone);
            this.userMobilePhone = this.init(obj.userMobilePhone);
            this.userSIN = this.init(obj.userSIN);
            this.userIsCurrentAddress = this.init(obj.userIsCurrentAddress);

            // Information sur le conjoint
            this.spouseGender = this.init(obj.spouseGender);
            this.spouseFirstName = this.init(obj.spouseFirstName);
            this.spouseLastName = this.init(obj.spouseLastName);
            this.spouseDOB = this.init(obj.spouseDOB);
            this.spouseSIN = this.init(obj.spouseSIN);
            this.spouseJobStatus = this.init(obj.spouseJobStatus);
            this.spouseEmployerName = this.init(obj.spouseEmployerName);
            this.spouseEmployerActivity = this.init(obj.spouseEmployerActivity);
            this.spouseJobTitle = this.init(obj.spouseJobTitle);

            // Information sur le conjoint cotisant
            this.contributingSpouseGender = this.init(obj.contributingSpouseGender);
            this.contributingSpouseFirstName = this.init(obj.contributingSpouseFirstName);
            this.contributingSpouseLastName = this.init(obj.contributingSpouseLastName);
            this.contributingSpouseDOB = this.init(obj.contributingSpouseDOB);
            this.contributingSpouseSIN = this.init(obj.contributingSpouseSIN);

            // Occupation et Employeur
            this.userEmployerIsBroker = this.init(obj.userEmployerIsBroker);
            this.userIsInvestmentAdvisorIndicator = this.init(obj.userIsInvestmentAdvisorIndicator);
            this.userEmployerName = this.init(obj.userEmployerName);
            this.userEmployerActivity = this.init(obj.userEmployerActivity);
            this.userJobTitle = this.init(obj.userJobTitle);
            this.userSameAddressWithBrokerEmployeeIndicator = this.init(obj.userSameAddressWithBrokerEmployeeIndicator);
            this.userSameAddressWithBrokerEmployeeVmdIndicator = this.init(obj.userSameAddressWithBrokerEmployeeVmdIndicator);
            this.userSameAddressWithBrokerEmployeeFirmName = this.init(obj.userSameAddressWithBrokerEmployeeFirmName);
            this.userSameAddressWithBrokerEmployeeFirstName = this.init(obj.userSameAddressWithBrokerEmployeeFirstName);
            this.userSameAddressWithBrokerEmployeeLastName = this.init(obj.userSameAddressWithBrokerEmployeeLastName);
            this.userSameAddressWithBrokerEmployeeLink = this.init(obj.userSameAddressWithBrokerEmployeeLink);
            this.userSameAddressWithBrokerEmployeeLinkList = this.init(obj.userSameAddressWithBrokerEmployeeLinkList);
            this.userChargeOfParentEmployeeAtVMDFirstName = this.init(obj.userChargeOfParentEmployeeAtVMDFirstName);
            this.userChargeOfParentEmployeeAtVMDLastName = this.init(obj.userChargeOfParentEmployeeAtVMDLastName);
            this.percentageOwner = this.init(obj.percentageOwner);

            // Lieu de résidence aux fins fiscales
            this.userFiscalRelationCanada = this.init(obj.userFiscalRelationCanada);
            this.userFiscalRelationUsa = this.init(obj.userFiscalRelationUsa);
            this.userFiscalRelationOther = this.init(obj.userFiscalRelationOther);
            this.userNifMissingReason = this.init(obj.userNifMissingReason);
            this.userNifMissingReason2 = this.init(obj.userNifMissingReason2);
            this.userNifMissingReason3 = this.init(obj.userNifMissingReason3);
            this.userCitizenship = this.init(obj.userCitizenship);
            this.userCitizenshipUS = this.init(obj.userCitizenshipUS);
            this.addUserOtherCitizenship = this.init(obj.addUserOtherCitizenship);
            this.userOtherCitizenship = this.init(obj.userOtherCitizenship);
            this.usaNif = this.init(obj.usaNif);
            this.userCountryNif2 = this.init(obj.userCountryNif2);
            this.userCountryNif3 = this.init(obj.userCountryNif3);
            this.codePremierAutreNif = this.init(obj.codePremierAutreNif);
            this.codeSecondAutreNif = this.init(obj.codeSecondAutreNif);
            this.fiscalUsCitizenshipIndicator = this.init(obj.fiscalUsCitizenshipIndicator);
            this.fiscalAddressDifferentIndicator = this.init(obj.fiscalAddressDifferentIndicator);
            this.fiscalAddressCountry = this.init(obj.fiscalAddressCountry);
            this.fiscalAddressProvince = this.init(obj.fiscalAddressProvince);

            // Comptes de courtage
            this.brokerageOtherAccountsIndicator = this.init(obj.brokerageOtherAccountsIndicator);
            this.brokerageOtherAccountsFirmName = this.init(obj.brokerageOtherAccountsFirmName);
            this.brokerageOtherAccountsAccountType = this.init(obj.brokerageOtherAccountsAccountType);
            this.userInitieIndicator = this.init(obj.userInitieIndicator);
            this.userInitieIndicatorOSS = this.init(obj.userInitieIndicatorOSS);
            this.userInitieCompanyName = this.init(obj.userInitieCompanyName);
            this.userInitieCompanySymbol = this.init(obj.userInitieCompanySymbol);
            this.userInitieMarket = this.init(obj.userInitieMarket);
            this.userMainShareholderIndicator = this.init(obj.userMainShareholderIndicator);
            this.userMainShareholderIndicatorOSS = this.init(obj.userMainShareholderIndicatorOSS);
            this.userMainShareholderCompanyName = this.init(obj.userMainShareholderCompanyName);
            this.userMainShareholderCompanySymbol = this.init(obj.userMainShareholderCompanySymbol);
            this.userMainShareholderMarket = this.init(obj.userMainShareholderMarket);
            this.userPoliticallyExposedForeignPersonIndicator = this.init(obj.userPoliticallyExposedForeignPersonIndicator);
            this.userFinancialInterestIndicator = this.init(obj.userFinancialInterestIndicator);
            this.userTrustedPersonIndicator = this.init(obj.userTrustedPersonIndicator);
            this.userOtherTradingPersonIndicator = this.init(obj.userOtherTradingPersonIndicator);
            this.brokeragePurpose = this.init(obj.brokeragePurpose);
            this.brokeragePurposeOther = this.init(obj.brokeragePurposeOther);
            this.userBrokerageKnowledge = this.init(obj.userBrokerageKnowledge);
            this.userOtherGuarantingPersonIndicator = this.init(obj.userOtherGuarantingPersonIndicator);
            this.userBrokerageCreditBalanceList = this.init(obj.userBrokerageCreditBalanceList);
            this.userBrokerageDirectDeposit = this.init(obj.userBrokerageDirectDeposit);
            this.userBrokerageWithdrawal = this.init(obj.userBrokerageWithdrawal);


            // Institution financiere
            this.userAnnualIncome = this.init(obj.userAnnualIncome);
            this.userNetCapitalAsset = this.init(obj.userNetCapitalAsset);
            this.userNetLiquidityAsset = this.init(obj.userNetLiquidityAsset);
            this.userTotalNetWorth = this.init(obj.userTotalNetWorth);
            this.userBankId = this.init(obj.userBankId);
            this.userBankBranchNumber = this.init(obj.userBankBranchNumber);
            this.userBankAccountNumber = this.init(obj.userBankAccountNumber);
            this.userBankPhone = this.init(obj.userBankPhone);
            // Communication avec les propriétaires véritables
            this.legalShareHolderMsgWarning = this.init(obj.legalShareHolderMsgWarning);

            // Communication avec les actionnaires
            this.userDisclaimerIndicator = this.init(obj.userDisclaimerIndicator);
            this.userReceiptDocumentSecuritiesHolderIndicator = this.init(obj.userReceiptDocumentSecuritiesHolderIndicator);
            this.userElectronicDocumentSecuritiesHolderIndicator = this.init(obj.userElectronicDocumentSecuritiesHolderIndicator);
            this.userMetAtOffice = this.init(obj.userMetAtOffice);
            this.authorizeClientInfoPresence = this.init(obj.authorizeClientInfoPresence);

            this.userIdentityAdvisorFamilyName = this.init(obj.userIdentityAdvisorFamilyName);
            this.userIdentityAdvisorFirstName = this.init(obj.userIdentityAdvisorFirstName);
            this.userIdentityAdvisorBranchName = this.init(obj.userIdentityAdvisorBranchName);
            this.userIdentityAdvisorInstitution = this.init(obj.userIdentityAdvisorInstitution);
            this.userIdentityAdvisorTransit = this.init(obj.userIdentityAdvisorTransit);

            this.userIdentityCode = this.init(obj.userIdentityCode);
            this.userIdentityIDNumber = this.init(obj.userIdentityIDNumber);
            this.userIdentityExpirationDate = this.init(obj.userIdentityExpirationDate);
            this.userIdentityProvince = this.init(obj.userIdentityProvince);
            this.userIdentityCountry = this.init(obj.userIdentityCountry);
            this.userIdentityVerificationDate = this.init(obj.userIdentityVerificationDate);

            this.userIdentities = [];
            this.userIdentities.push(new UserIdentity({}));
            this.userIdentities.push(new UserIdentity({}));
            if (obj.userIdentities && obj.userIdentities.length > 0) {
                this.userIdentities = [];

                for (const userIdentity of obj.userIdentities) {

                    this.userIdentities.push(new UserIdentity(userIdentity));
                }
            }

            this.isSameAddressAsFirstIndicator = this.init(obj.isSameAddressAsFirstIndicator);

            this.consent = this.init(obj.consent);

            this.personalInformationSpecificConsentIndicator = this.init(obj.personalInformationSpecificConsentIndicator);
        }
    }

}
