import {AfterViewInit, Component, ElementRef, OnDestroy, TemplateRef, ViewChild} from '@angular/core';
import {NavigationService} from '../../../components/services/navigation.service';
import {AbstractControl, FormArray, FormBuilder, FormGroup, NgForm, ValidatorFn, Validators} from '@angular/forms';
import {FormService} from '../../../components/services/form.service';
import {VmdConstants} from '../../../components/constants/vmd-constants';
import {VmdForm} from '../../../components/utils/vmd-form';
import {TranslateService} from '@ngx-translate/core';
import {I18N_MESSAGES_EN} from '../../../components/i18n/messages_en';
import {I18N_MESSAGES_FR} from '../../../components/i18n/messages_fr';
import {HelpComponent} from '../help/help.component';
import {VmdValidators} from '../../../components/validators/vmd-validators';
import {Functions} from '../../../components/utils/functions';
import {WebException} from '../../../components/models';
import {HttpStatusCode} from '@angular/common/http';

declare let $: any;

@Component({
    selector: 'app-base-nav',
    template: '',
    providers: [VmdForm]
})
export class BaseNavComponent implements AfterViewInit, OnDestroy {

    public form: FormGroup = new FormGroup({});
    public constants = VmdConstants;
    public formSubmitted = false;
    public subscriptions: any[] = [];
    error: WebException = null;

    public requesterIndex = 0;

    public clientTypeRequest: string;

    @ViewChild(HelpComponent) helpComponent: HelpComponent;
    @ViewChild(NgForm) ngForm: NgForm;
    @ViewChild(NgForm, {read: ElementRef}) formRef: ElementRef;

    constructor(protected navigationService: NavigationService,
                protected fb: FormBuilder,
                protected formService: FormService,
                protected translate: TranslateService) {
        this.navigationService.emitIsBackDisabled(false);
        this.formService.setCurrentComposant(this);

        this.clientTypeRequest = this.formService.getForm().cltType;
    }

    showMention(): boolean {
        const cltType = this.formService.getClientType();
        return this.isPleinEx() && (cltType === VmdConstants.CLT_TYPE_ASSOCIATION ||
            cltType === VmdConstants.CLT_TYPE_CLUB ||
            cltType === VmdConstants.CLT_TYPE_CORPORATION ||
            cltType === VmdConstants.CLT_TYPE_COOPERATIVE ||
            cltType === VmdConstants.CLT_TYPE_SENC ||
            cltType === VmdConstants.CLT_TYPE_OSBL ||
            cltType === VmdConstants.CLT_TYPE_SOC_COMM);
    }

    ngAfterViewInit() {
        this.translate.onLangChange.subscribe(() => {
            this.initHelpClick();
        });
        this.initHelpClick();

        this.navigationService.emitAfterViewInit();
    }

    ngOnDestroy() {
        if (this.subscriptions.length > 0) {
            for (const subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    clickSubmitButton(): void {
        this.formRef.nativeElement.querySelector('[type="submit"]').click();
    }

    focusOnErrorsList() {
        setTimeout(() => {
            const firstError = ($('#boite-erreurs').first().length > 0) ? $('#boite-erreurs').first() : $('.has-error input, .has-error select').first();
            if (firstError.length > 0) {
                $(firstError).focus();
            }
        }, 0);
    }

    getControl(path: string): AbstractControl {
        return this.form.get(path);
    }

    getForm(): FormGroup {
        return this.form;
    }

    getMessage(key: string): string {
        if (this.translate.currentLang === this.constants.LANG_EN) {
            return I18N_MESSAGES_EN[key];
        } else {
            return I18N_MESSAGES_FR[key];
        }
    }

    getRequesterPath(name: string): string {
        return 'requesters.' + this.requesterIndex + '.' + name;
    }

    requiredValidator(fieldname: string): ValidatorFn | null {
        return VmdValidators.required(this.formService.isFieldRequired(fieldname));
    }

    requiredGroupValidator(validator: ValidatorFn): ValidatorFn | null {
        if (this.isPleinEx() && this.formService.isPrintMode()) {
            return Validators.nullValidator;
        }

        return validator;
    }

    requiredGroupValidatorOnSpecificOthersClientTypes(validator: ValidatorFn): ValidatorFn | null {

        const clientType = this.formService.getClientType();

        if (Functions.isSpecificClientTypes(clientType, VmdConstants.CLT_TYPE_ASSOCIATION,
            VmdConstants.CLT_TYPE_CLUB,
            VmdConstants.CLT_TYPE_COOPERATIVE,
            VmdConstants.CLT_TYPE_CORPORATION,
            VmdConstants.CLT_TYPE_CO_UNIQUE,
            VmdConstants.CLT_TYPE_ORG_GOUV,
            VmdConstants.CLT_TYPE_OSBL,
            VmdConstants.CLT_TYPE_SENC,
            VmdConstants.CLT_TYPE_SOC_COMM,
            VmdConstants.CLT_TYPE_FIDUCIE)) {

            return this.requiredGroupValidator(validator);
        }
    }

    requiredAttorneyPhoneGroupValidator(validator: ValidatorFn): ValidatorFn | null {
        if (this.isPleinEx()) {
            return Validators.nullValidator;
        } else {
            return validator;
        }
    }


    isExcludeStepNavigation(): boolean {
        return false;
    }

    isFieldInError(path: string) {
        const control: AbstractControl = this.form.get(path);

        if (control === null) {
            return false;
        }

        return control.touched && control.invalid
            && ((control.hasError('required') && this.ngForm.submitted) || !control.hasError('required'));
    }

    isFormSubmitted(): boolean {
        if (this.ngForm) {
            return this.ngForm.submitted;
        }

        return false;
    }

    isInvalidForm(): boolean {
        return this.form.invalid;
    }

    isPleinEx(): boolean {
        return this.formService.isPleinEx();
    }

    isGpdOrSfd(): boolean {
        return this.formService.isGPDOrSFD();
    }

    isGpd(): boolean {
        return this.formService.isGPD();
    }

    isSsd(): boolean {
        return this.formService.isSSD();
    }

    isSfd(): boolean {
        return this.formService.isSFD();
    }

    useSwitchCeliApp(): boolean {
        return this.formService.isCELIAPP();
    }

    useSwitchLba(): boolean {
        return this.formService.isLBA();
    }

    isPilotMemberOrModeUpdateAutomatedOpeningActive() {
        return this.formService.isPilotMemberOrModeUpdateAutomatedOpeningActive();
    }

    isPilotMember(): boolean {
        return this.formService.isPilotMember();
    }

    isUpdateAccount(): boolean {
        return this.formService.isUpdateAccount();
    }

    isOtherAccountAssociation(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_ASSOCIATION;
    }

    isOtherAccountCooperative(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_COOPERATIVE;
    }

    isOtherAccountCorporation(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CORPORATION;
    }

    isOtherAccountEsQualitesTuteur(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_ENF_ES_QUALITES;
    }

    isOtherAccountEntrepriseIndividuelle(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_CO_UNIQUE;
    }

    isOtherAccountFiducie(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_FIDUCIE;
    }

    isOtherAccountOrganisationGouvernementale(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_ORG_GOUV;
    }

    isOtherAccountOSBL(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_OSBL;
    }

    isOtherAccountRRI(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_RRI;
    }

    isOtherAccountSENC(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_SENC;
    }

    isOtherAccountSocieteCommandite(): boolean {
        return this.formService.getForm().cltType === this.constants.CLT_TYPE_SOC_COMM;
    }

    isEligibleToShowCommunicationBeneficialOwnersSection(): boolean {
        return this.formService.isEligibleToShowCommunicationBeneficialOwnersSection();
    }

    isSsdPortfolioManagementAdvisorProgram(): boolean {
        return this.formService.isSSD() && this.formService.getForm().portfolioManagementAdvisorProgram;
    }

    isVmdPortfolioManagementMandateOnly(): boolean {
        return this.isPleinEx() && this.formService.getForm().portfolioManagementMandateOnly;
    }

    isRedocumented(): boolean {
        return this.constants.ACCOUNT_REDOCUMENT === this.formService.getForm().newExistAct;
    }

    initHelpClick() {
        setTimeout(() => {
            $('.inline-help-link').unbind('click');
            $('.inline-help-link').on('click', (e: Event) => {
                e.preventDefault();
                this.showHelp();
            });
        }, 0);

    }

    markAsTouched(control: FormGroup): void {
        if (control) {
            Object.keys(control.controls).forEach(path => {
                if (control.get(path) instanceof FormGroup || control.get(path) instanceof FormArray) {
                    this.markAsTouched(control.get(path) as FormGroup);
                } else {
                    control.get(path).markAsTouched();
                }
            });
        }
    }

    navBack() {
        if (
            this.isPleinEx()
            && (
                this.isInvalidForm()
                || (this.formService.getCurrentComposant() && this.formService.getCurrentComposant().processRules())
            )
        ) {
            this.clickSubmitButton();
        } else {
            this.navigationService.emitBack();
        }
    }

    navNext() {
        this.navigationService.emitNext();
        this.navigationService.emitStepMetricForm();
    }

    processRules(): boolean {
        return false;
    }

    setForm(form: FormGroup): void {
        this.form = form;
    }

    showHelp() {
        if (this.helpComponent) {
            // this.helpComponent.showAndScroll();
        }
    }

    showBack() {
        let showBack = (this.navigationService.currentTab !== 1 || this.navigationService.currentSubTab !== 0);

        if (!showBack && this.formService.getForm().cltType !== this.constants.CLT_TYPE_INDIVIDU) {
            showBack = true;
        }

        if (this.isPleinEx()) {
            showBack = true;
        }

        return showBack;
    }

    submitForm(triggerNext: boolean = true) {
        this.formSubmitted = true;

        this.markAsTouched(this.form);

        if (!this.formService.isFromSmartD() && this.formService.isCaisseContext()) {
            if (this.form.valid || this.form.disabled) {
                if (!this.form.invalid) {
                    if (triggerNext) {
                        this.navNext();
                    }
                } else {
                    this.focusOnErrorsList();
                }
            }
        } else {
            if (this.form.valid) {
                if (triggerNext) {
                    this.navNext();
                }
            } else {
                this.focusOnErrorsList();
            }
        }
    }

    updateControlEnabled(control: AbstractControl, enabledCondition: boolean): void {
        if (enabledCondition) {
            if (control.disabled) {
                control.enable();
                control.markAsUntouched();
            }
        } else {
            if (control.enabled) {
                control.disable();

                // fix bug - sometimes, it is still enabled. Do it again
                if (control.enabled) {
                    control.disable();
                }
            }
        }
    }

    getTitleHelpTemplateFr(): TemplateRef<any> {
        return null;
    }

    getTitleHelpTemplateEn(): TemplateRef<any> {
        return null;
    }

    // Returns if the form is saving or processing without showing a modal used to block some user actions
    isRequestInProgress(): boolean {
        return this.formService.saveInProgressWithoutModal();
    }

    isRouteOtherAccountGPD(): boolean {
        return this.constants.OTHER_ACCOUNT_GPD_ROUTES_CONFIG_KEY === this.navigationService.stepRoutesConfigKey;
    }

    showMarginAccount() {
        return !this.formService.getForm().portfolioManagementMandateOnly;
    }

    showMarginAccountWithShortSelling() {
        return this.formService.getForm().portfolioManagementAdvisorProgram && !this.isSsd();
    }

    isUpdateDateBeforeMaxDate(): boolean {
        return this.formService.isUpdateDateBeforeMaxDateOrNull();
    }

    isRecoveredRequest(): boolean {
        return this.formService.isRecoveredRequest();
    }

    hideSubTabFinancialInfos(value: string) {
        if (this.isPilotMemberOrModeUpdateAutomatedOpeningActive()
            && value === VmdConstants.USER_EMPLOYER_IS_BROKER_VMD
            && !this.isPleinEx()) {
            this.navigationService.disableSubTabFinancialInfos(true);
        } else {
            this.navigationService.disableSubTabFinancialInfos(false);
        }
    }

    protected onCompleteFormError(error: WebException) {
        if (error) {
            if (error.status !== HttpStatusCode.Unauthorized && error.status !== HttpStatusCode.Forbidden) {
                this.error = error;
            }
        }
    }
}
